import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type BarChartEntry = {
    time: Date;
    value: number;
}

type BarChartConfig = {
    data: BarChartEntry[];
    title: string;
    minuteGranularity?: number;
}

export default function BarChart(props: BarChartConfig) {

  //Bail if there is no data
  if (!props?.data?.length) {
    return <></>;
  }

  const summaryData = new Map<string, number>();
  const zeroPad = (num) => String(num).padStart(2, '0')

  const getHourString = (date: Date) => {

    //Get the date in the format MMM dd HH:mm
    const month = zeroPad( date.getMonth() + 1);
    const day = zeroPad(date.getDate());
    const hour = zeroPad( date.getHours());
    const minute = zeroPad( date.getMinutes() - (date.getMinutes() % (props.minuteGranularity ?? 5)));

    
    //Return with leading zeroes on hour and minute
    //return date;
    return `${date.getFullYear()}-${month}-${day} ${hour}:${minute.toString()}`;
  }
  

  //Sort by time
  props.data.sort((a, b) => {
    const aDate = new Date(a['time']);
    const bDate = new Date(b['time']);
    return aDate.getTime() - bDate.getTime();
  });

  //HACK: insert dummy entries in the data to fill in the gaps
  const firstDate = new Date( getHourString(new Date(props.data[0]['time'])));
  const lastDate = new Date(getHourString(new Date()));


  let dateVal = firstDate;

  const timeLabels: string[]= [];

  timeLabels.push(getHourString(dateVal));
  while (dateVal.getTime() <= lastDate.getTime()) {
    dateVal = new Date(dateVal.getTime() + ((props.minuteGranularity ?? 5) * 60 * 1000));
    timeLabels.push(getHourString(dateVal));
  }

  props.data.forEach((d) => {
    const hourString = getHourString(d.time);
    const count = summaryData.get(hourString);
    if (count) {
      summaryData.set(hourString, count + d.value);
    } else {
      summaryData.set(hourString, d.value);
    }
  });


  const xyVals = timeLabels.map((label) => {
    return {x: label, y: summaryData.get(label) ?? 0};});
    

  const data = {
    //labels,
    datasets: [
      {
        label: props.title,
        data: xyVals,
        backgroundColor: '#317218bb',
      },     
    ],
  };

  const options = {
    type: 'bar',
    responsive: true,
    spanGaps: true,
    
    options: {
      
      scales: {
        
        x: {
          
          type: 'time',
          parsing: true,         
          
        }
      }
    },
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>        
        <Bar options={options} data={data} />
      </Paper>
     
    </Box>
  );
}
