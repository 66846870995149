import '../style.css';
import React, { useState } from 'react';
import { Button, ButtonGroup, Grid, SxProps } from '@mui/material';
import { DataTableConfig } from '../components/DataTable';
import FancyTable, { TableColumn, TableConfig } from '../components/FancyTable';
import DataModal from '../components/DataModal';
import { getSqlLogs, getSqlStats } from '../common/api';
import { SqlLog, SqlStat } from '../types/sqlLog';
import { BarChartEntry } from '../components/BarChart';
//import DataModal from './DataModal';


export default function SqlLogList() {

  const [data, setData] = useState<SqlStat[]>([]);
  const [timeFilter, setTimeFilter] = useState<number>(1);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [detailData, setDetailData] = useState<TableConfig<SqlLog>>(undefined);


  const handleClose = () => {
    console.log('closing');
    setSelectedId(0);
    setDetailData(undefined);
  };

  const clickUrl = (id: number) => { 
    console.log(`clickUrl ${id}`);
    setSelectedId(id);
  };

  const clickTimeFilter = (value: number) => {
    setTimeFilter(value);
    setRefresh(true);    
  }  

  const getDurationChartData = (data: {id: number}[]): BarChartEntry[] => {
    return data?.map((item) => {      
      return { 
        time: new Date(item['time']),
        value: item['elapsed'],    
      };
    });
  };

  const getCountChartData = (data: {id: number}[]): BarChartEntry[] => {
    console.log('getCountChartData');
    return data?.map((item) => {      
      return { 
        time: new Date(item['time']),
        value: item['execCount'],
      };
    });
  };

  /* TABLE SPEC */
  const tableSpec: DataTableConfig = {
    data: data,
    idField: 'id',
    chartConfig: {
      durationDataFn: getDurationChartData,
      countDataFn:    getCountChartData,
      minuteGranularity: timeFilter <= 1 
        ? 5
        : timeFilter <= 2
          ? 10
          : timeFilter <= 6
            ? 15
            : timeFilter <= 24
              ? 60
              : 120,
    },
    columns: [
      { title: 'id', field: 'id', dataType: 'number', align: 'left', clickFn: clickUrl },
      { title: 'App', field: 'app', clickFn: clickUrl },
      { title: 'text', field: 'text', clickFn: clickUrl, textFn: (value) => value.substring(0, 500),
        styleFn: ()=> ({maxWidth: '700px'} as SxProps) },
      { title: 'Times Run', field: 'execCount', dataType: 'number', clickFn: clickUrl },
      { title: 'Avg Elapsed (ms)', field: 'avgElapsed', dataType: 'number', clickFn: clickUrl },
      { title: 'Total Time (sec)', field: 'totalElapsed', dataType: 'number', clickFn: clickUrl, textFn: (value) => `${value/1000}` }      
    ]
  };

  const detailCols :TableColumn[] = 
  [
    { title: 'id', field: 'id', dataType: 'number' },
    { title: 'app version', field: 'version', dataType: 'string' },
    { title: 'time', field: 'time', dataType: 'string' },
    { title: 'Times Run', field: 'execCount', dataType: 'number' },
    { title: 'Avg Elapsed', field: 'elapsed', dataType: 'number', clickFn: clickUrl, textFn: (value) => `${value} ms` },
  ];

  const timeFilterBtn = (label: string, value: number) => {
    return <Button variant={value == timeFilter ? 'contained': 'outlined'} onClick={() => clickTimeFilter(value)}>{label}</Button>;
  }

  if (refresh) {
    getSqlStats(timeFilter)
      .then((data) => {
        setData( data );
        setRefresh(false);
      })
  }

  if (selectedId > 0 && detailData == undefined) {

    const selItemDetail = data.find((item) => item.id == selectedId)?.text;

    getSqlLogs(selectedId, timeFilter)
      .then((data) => {        
        setDetailData({
          columns: detailCols,
          data: data,
          cmdText: selItemDetail,
        });
        setRefresh(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div style={{maxWidth: 1800, marginLeft: 'auto', marginRight: 'auto'}}>      
      <Grid container>
        <Grid xs={12} md={4} className='page-title'>SQL Log listing</Grid>
        <Grid xs={12} md={8} sx={{display: 'flex', justifyContent:'right', alignItems: 'center'}} >       
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {timeFilterBtn('1 hr', 1)}
            {timeFilterBtn('6 hr', 6)}
            {timeFilterBtn('24 hr', 24)}
            {timeFilterBtn('7 Days', 168)}
          </ButtonGroup>
          <DataModal title='SQL Details' open={selectedId > 0 && detailData != undefined} config= {detailData} handleClose={handleClose} chartConfig= {tableSpec.chartConfig} />
        </Grid>
      </Grid>
      <FancyTable columns={tableSpec.columns} data={tableSpec.data} />
    </div>
  );
}
  