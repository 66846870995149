import '../style.css';
import React from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { deletePlatelyUser } from '../common/api';


export default function PlatelyDeleteUser() {

  const [email, setEmail] = React.useState<string>('');
  const [error, seError] = React.useState<string>('');
  const [msg, setMsg] = React.useState<string>('');

  const deleteUser = () => {
    console.log('Delete User: ' + email);
    try {
      deletePlatelyUser(email).then((msg) => {
        setMsg(msg);
        seError('');
      }).catch((err) => {
        seError(err.message);
        setMsg('');
      });
    }
    catch (err) {
      seError(err.message);
    }
  }

  return (
    <div style={{ minHeight: 400, maxWidth: 1800, marginLeft: 'auto', marginRight: 'auto' }}>

      <h2>Delete Plately User</h2>


      <Grid container >
        <Grid xs={6} md={3} textAlign={'center'} alignContent={'center'}>
          <Typography fontSize={18} fontWeight={'bold'}>Email:</Typography>
        </Grid>
        <Grid xs={6} md={3}>
          <TextField fullWidth variant="outlined" onChange={(e) => setEmail(e.target.value)} />
        </Grid>
        <Grid xs={12} md={3} alignContent={'center'}>
          <Button variant="contained" color="error" onClick={() => deleteUser()}>Delete User</Button>
        </Grid>
        <Grid xs={12} md={12} textAlign={'center'} alignContent={'center'}>
          <Typography fontSize={18} fontWeight={'bold'} color={'error'}>{error}</Typography>
        </Grid>
        <Grid xs={12} md={12} textAlign={'center'} alignContent={'center'}>
          <Typography fontSize={18} fontWeight={'bold'} color={'success'}>{msg}</Typography>
        </Grid>

      </Grid>

    </div>
  );
}