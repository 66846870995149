import '../style.css';
import React, { useState } from 'react';
import { HttpRequestLog, HttpRequestStat } from '../types/httpLog';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { DataTableConfig } from '../components/DataTable';
import FancyTable, { TableColumn, TableConfig } from '../components/FancyTable';
import DataModal from '../components/DataModal';
import { addMsLabel, redStyleIfNonZero, redStyleIfNot200, redStyleIfNotBlank, showValueIfNonZero } from '../common/formats';
import { getHttpLogs, getHttpStats } from '../common/api';
import { BarChartEntry } from '../components/BarChart';
//import DataModal from './DataModal';


export default function HttpLogList() {

  const [data, setData] = useState<HttpRequestStat[]>([]);
  const [timeFilter, setTimeFilter] = useState<number>(1);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [detailData, setDetailData] = useState<TableConfig<HttpRequestLog>>(undefined);
    

  const detailCols :TableColumn[] = 
    [
      { title: 'id', field: 'id', dataType: 'number' },
      { title: 'app version', field: 'version', dataType: 'string' },
      { title: 'time', field: 'time', dataType: 'string' },
      { title: 'status', field: 'status', dataType: 'number', styleFn: redStyleIfNot200 },      
      { title: 'error', field: 'error', dataType: 'string', styleFn: redStyleIfNotBlank },
      { title: 'query', field: 'query', dataType: 'string' },
      { title: 'params', field: 'params', dataType: 'string' },
      { title: 'elapsed', field: 'elapsed', dataType: 'number', textFn: addMsLabel }
    ];

  if (refresh) {
    getHttpStats(timeFilter)
      .then((data) => {
        setData( data );
        setRefresh(false);
      })
  }

  if (selectedId > 0 && detailData == undefined) {

    const selItemDetail = data.find((item) => item.id == selectedId)?.url;

    getHttpLogs(selectedId, timeFilter)
      .then((data) => {        
        setDetailData({
          columns: detailCols,
          data: data,
          cmdText: selItemDetail
        });
        setRefresh(false);
      });

  }


  const handleClose = () => {
    console.log('closing');
    setSelectedId(0);
    setDetailData(undefined);
  };

  const clickUrl = (id: number) => { 
    console.log(`clickUrl ${id}`);
    setSelectedId(id);
  };

  const clickTimeFilter = (value: number) => {
    setTimeFilter(value);
    setRefresh(true);    
  }  

  const getDurationChartData = (data: {id: number}[]): BarChartEntry[] => {
    return data?.map((item) => {      
      return { 
        time: new Date(item['time']),
        value: item['elapsed'],    
      };
    });
  };

  const getCountChartData = (data: {id: number}[]): BarChartEntry[] => {
    return data?.map((item) => {      
      return { 
        time: new Date(item['time']),
        value: 1,    
      };
    });
  };

  /* TABLE SPEC */
  const tableSpec: DataTableConfig = {
    data: data,
    idField: 'id',
    chartConfig: {
      durationDataFn: getDurationChartData,
      countDataFn:    getCountChartData,
      minuteGranularity: timeFilter <= 1 
        ? 5
        : timeFilter <= 2
          ? 10
          : timeFilter <= 6
            ? 15
            : timeFilter <= 24
              ? 60
              : 120,
    },
    columns: [
      { title: 'id', field: 'id', dataType: 'number', align: 'left', clickFn: clickUrl },
      { title: 'App', field: 'app', clickFn: clickUrl },
      { title: 'url', field: 'url', clickFn: clickUrl },      
      { title: 'Times Run', field: 'execCount', dataType: 'number', clickFn: clickUrl },
      { title: 'Error (500)', field: 'errorCount', dataType: 'number', clickFn: clickUrl, 
        textFn: showValueIfNonZero, styleFn: redStyleIfNonZero },      
      { title: 'Invalid (400)', field: 'invalidCount', dataType: 'number', clickFn: clickUrl, 
        textFn: showValueIfNonZero, styleFn: redStyleIfNonZero },
      { title: 'Forbidden (403)', field: 'forbiddenCount', dataType: 'number', clickFn: clickUrl, 
        textFn: showValueIfNonZero, styleFn: redStyleIfNonZero },
      { title: 'Missing (404)', field: 'notFoundCount', dataType: 'number', clickFn: clickUrl, 
        textFn: showValueIfNonZero, styleFn: redStyleIfNonZero },
      { title: 'Response Time', field: 'avgElapsed', dataType: 'number', clickFn: clickUrl, 
        textFn: addMsLabel },
      
    ]
  };

  const timeFilterBtn = (label: string, value: number) => {
    return <Button variant={value == timeFilter ? 'contained': 'outlined'} onClick={() => clickTimeFilter(value)}>{label}</Button>;
  }

  return (
    <div style={{maxWidth: 1800, marginLeft: 'auto', marginRight: 'auto'}}>      
      <Grid container>
        <Grid xs={12} md={4} className='page-title'>HTTP Log listing</Grid>
        <Grid xs={12} md={8} sx={{display: 'flex', justifyContent:'right', alignItems: 'center'}} >       
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {timeFilterBtn('1 hr', 1)}
            {timeFilterBtn('6 hr', 6)}
            {timeFilterBtn('24 hr', 24)}
            {timeFilterBtn('7 Days', 168)}
          </ButtonGroup>
          <DataModal title='HTTP Details' open={selectedId > 0 && detailData != undefined} config= {detailData} handleClose={handleClose} chartConfig={tableSpec.chartConfig} />
        </Grid>
      </Grid>
      <FancyTable columns={tableSpec.columns} data={tableSpec.data} />
    </div>
  );
}
  