import '../style.css';
import React, { useState } from 'react';
import { Grid, Typography, Button, Card, CardContent, TextField, MenuItem, Stack, List, ListItem, ListItemButton, IconButton, Container } from '@mui/material';
import { getModerationDecision, postModerationDecision } from '../common/api';
import { ModerationDecision, ModerationOption, ModerationParam } from '../../../src/types/platelyMod';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { timeAgo } from '../common/formats';

type ModDecision = {
  decision: ModerationDecision;
  index?: number;
}

type ModOption = {
  option: ModerationOption
  url: string;
  index: number;
}

type ModParam = {
  param: ModerationParam
}


export default function PlatelyModerationTool() {


  const [decisionList, setDecisionList] = useState<ModerationDecision[]>();
  const [decisionSelect, setDecisionSelect] = useState<number>();
  const [error, setError] = useState();

  const handleClick = async (url, option) => {

    setError(null);

    try {
      await postModerationDecision(url, option);
    } catch (error) {
      setError(error);
      console.log(error);
      return;
    }
    getModerationDecision().then((data) => {
      setDecisionList(data);
    });
  }

  if (!decisionList) {
    getModerationDecision().then((data) => {
      setDecisionList(data);
      setDecisionSelect(0);
    });
  }
  
  return (
    <div style={{ minHeight: 400, maxWidth: 1800, margin: 'auto', backgroundColor: 'lightgray' }}>
      <Grid container columns={12} padding={1} gap={1}>
        <Grid xs={6} md={2}>
          <List sx={{ bgcolor: 'background.paper', maxHeight: 490, overflow: 'auto', padding: 1.5, paddingRight: 0, paddingLeft: 0 }}>
            {decisionList?.map((e, index) => <DecisionListItem key={e.id} decision={e} index={index} />)}
          </List>
        </Grid>
        <Grid xs>
          {decisionList && decisionList[decisionSelect] ?
            <DecisionCard decision={decisionList[decisionSelect]} index={decisionSelect} />
            : <Card><CardContent><Typography>All Moderation Decisions Resolved</Typography></CardContent></Card>}
          <Typography hidden={!error} sx={{ color: 'red' }}>{`${error}`}</Typography>
        </Grid>
      </Grid>
    </div>
  );

  function DecisionListItem(props: ModDecision) {
    return (<ListItem disablePadding={true}>
      <ListItemButton alignItems='flex-start' onClick={() => { setDecisionSelect(props.index); setError(null); }} selected={decisionSelect === props.index}>
        <Grid container gap={2}>
          {/* <Grid>{props.decision.id}</Grid> */}
          <Grid>{props.decision.title}</Grid>
        </Grid>
      </ListItemButton>
    </ListItem>);
  }

  function DecisionCard(props: ModDecision) {

    const dateRaw = new Date(props.decision?.createdDate);

    return (
      <Card style={{ marginLeft: 0, marginRight: 0 }}>
        <CardContent>
          <Grid container>
            <Grid xs>
              <Stack textAlign={'center'}>
                {/* <Typography variant='body1'>{props.decision?.id}</Typography> */}
                <Typography variant='h5'>{props.decision?.title}</Typography>
                <Typography variant='caption'>{timeAgo(dateRaw)}</Typography>
              </Stack>
            </Grid>
            <Grid xs={'auto'}>
              {decisionList[decisionSelect - 1] ? <IconButton onClick={() => setDecisionSelect(decisionSelect - 1)}><FaArrowLeft /></IconButton> : <IconButton disabled><FaArrowLeft color='lightgray' /></IconButton>}
              {decisionList[decisionSelect + 1] ? <IconButton onClick={() => setDecisionSelect(decisionSelect + 1)}><FaArrowRight /></IconButton> : <IconButton disabled><FaArrowRight color='lightgray' /></IconButton>}
            </Grid>
          </Grid>
        </CardContent>
        <Grid container padding={2} spacing={0} >
          <Grid item xs={true}>
            <Card sx = {{minHeight: 200 }}>
              <CardContent>
                <Stack margin={0} padding={1} divider={<Container sx={{minHeight: 5}} />}>
                  {props.decision.options.map((e) => e.params
                    ? null
                    : <Button variant='contained' fullWidth key={e.label} onClick={() => handleClick(props.decision.url, e)}>{e.label}</Button>)
                  }
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          {props.decision.options.map((e) => e.params
            ? <Grid item key={e.label} xs={true}>
              <ParamOption
                key={e.label}
                option={e}
                url={props.decision.url}
                index={props.index} />
            </Grid>
            : null)}
        </Grid>
      </Card >
    )
  }


  function ParamOption(props: ModOption) {

    return (
      <Card sx = {{minHeight: 200 }}>
        <CardContent>
          <Grid container spacing={2} columns={4}>
            {props.option.params?.map((e) => (
              <Grid item key={e.name} xs={'auto'}>
                <ParamField key={e.name} param={e} />
              </Grid>))}
          </Grid>
        </CardContent>
        <Grid paddingX={2} paddingBottom={2}>
          <Button variant='contained' fullWidth onClick={() => handleClick(props.url, props.option)}>{props.option.label}</Button>
        </Grid>
      </Card>)

    function ParamField(props: ModParam) {

      const [param, setParam] = useState(props.param.default);

      const handleChange = (event) => {
        setParam(event.target.value);
      }

      props.param.value = param;

      switch (props.param.type.toLowerCase()) {
      default:
        return <TextField
          label={props.param.label}
          value={param}
          onChange={handleChange}>
        </TextField>;
      case 'number':
        return <TextField
          type='number'
          label={props.param.label}
          value={param}
          onChange={handleChange}>
        </TextField>;
      case 'boolean':
        return <TextField
          select
          label={props.param.label}
          value={param}
          onChange={handleChange}>
          <MenuItem value='false'>false</MenuItem>
          <MenuItem value='true'>true</MenuItem>
        </TextField>;
      }
    }
  }
}