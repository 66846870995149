import React from 'react'
import { Grid,Paper, Avatar, TextField, Button, Typography } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type LoginProps = {
    onUserLoginSuccess: () => void;
}

function Login(props: LoginProps){


  const submitLogin = () => { 
    const usr: any = {
      email: email,
      uName: email,
      pwd: pwd,
      inviteToken: inviteCode
    };

    const url = useInviteCode ? '/api/login' : '/api/login';

    fetch(url, {
      method: 'POST',      
      credentials: 'include',
      headers: {                
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(usr)
    })
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          props.onUserLoginSuccess();
        } else {
          setLoginError(data.message);
        }
      } )
      .catch(err => {
        setLoginError(err);
        console.log('Login Error' + err)
      });      
  };

  const [useInviteCode, setUseInviteCode] = React.useState(false);
  const [loginError, setLoginError] = React.useState('');
  const [ email, setEmail ] = React.useState('');
  const [ pwd, setPwd ] = React.useState('');
  const [ inviteCode, setInviteCode ] = React.useState('');

  const paperStyle={padding :20,height:'40vh',width:400, margin:'20px auto'}
  const avatarStyle={backgroundColor:'#1bbd7e'}
  const btnstyle={margin:'8px 0'}
  return(
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid alignContent={'center'}>
          <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar>
          <h2>Sign In</h2>
        </Grid>       
        <div >
          { useInviteCode && <TextField onChange={(e) => setInviteCode(e.target.value)} label='Paste Invite Code Here' placeholder='Paste Invite Code Here' variant="outlined" fullWidth required/> }   
          <TextField onChange={(e) => setEmail(e.target.value)} label='Email' placeholder='Enter Email' variant="outlined" fullWidth required/>
          <TextField onChange={(e) => setPwd(e.target.value)} label='Password' placeholder={useInviteCode ? 'Create 10+ character password': 'Enter Password'} type='password' variant="outlined" fullWidth required/>
          <Typography color={'red'} >{loginError}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="checkedB"
                color="primary"
              />
            }
            label="Remember me"
          />
          <Button onClick={() => submitLogin()} type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Sign in</Button>
        </div>
        <Typography >
          <Button onClick={() => setUseInviteCode(true)} >
                        Use Invite Code
          </Button>
        </Typography>
        
      </Paper>
    </Grid>
  )
}

export default Login