import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FancyTable, { TableConfig, IdData } from './FancyTable';
import { Button, ButtonGroup, Grid, Icon, IconButton, SvgIcon, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BarChartIcon from '@mui/icons-material/BarChart';
import BarChart from './BarChart';
import { ChartConfig } from './DataTable';


export type DataModalProps = {
    title: string;
    open: boolean;
    config: TableConfig<IdData>;
    chartConfig?: ChartConfig
    handleClose: () => void;
};

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
  
export default function DataModal(props: DataModalProps) {
  
  const [tab, setTab] = React.useState<number>(1);

  const handleClose = () => {
    props.handleClose();
  };

  const clickTab = (value: number) => {
    setTab(value);
  }  

  const tabBtn = (label: string, value: number, icon: typeof SvgIcon) => {
    return <Button variant={value == tab ? 'contained': 'outlined'} onClick={() => clickTab(value)}>
      <>
        <Icon component={icon} />
        {'   '}
        {label}
      </>
    </Button>;
  }

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={'xl'}
        
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {props.title} {props.config?.cmdText ? <Typography color='darkgray' variant='body2' component={props.config?.cmdText.length > 100? 'div': 'span'} noWrap={false} padding='16px' >{props.config?.cmdText}</Typography>: null}
        </BootstrapDialogTitle>
        <DialogContent dividers={true}>
          <Grid xs={12} container spacing={2} sx={{display:'flex', justifyContent:'right'}} >
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              {tabBtn('Raw Data', 1, FormatListBulletedIcon)}
              {tabBtn('Call Count', 2, BarChartIcon)}
              {tabBtn('Duration', 3, BarChartIcon)}
            </ButtonGroup>
          </Grid>
          {tab == 1 && <FancyTable data={props.config?.data} columns={props.config?.columns} />}
          {tab == 2 && <BarChart title='Call Count' minuteGranularity={props.chartConfig?.minuteGranularity} data={props.chartConfig?.countDataFn(props.config?.data)} />}
          {tab == 3 && <BarChart title='Total Duration' minuteGranularity={props.chartConfig?.minuteGranularity} data={props.chartConfig?.durationDataFn(props.config?.data)} />}
          
        </DialogContent>
      </Dialog>
    </div>
  );
}