import React from 'react';
import './style.css';
import HttpLogList from './screens/HttpLog';
import MenuAppBar from './components/AppBar';
import { ThemeProvider } from '@emotion/react';
import { appTheme } from './mui.theme';
import SqlLogList from './screens/SQLLog';
import { AppFooter } from './components/AppFooter';
import Login from './components/Login';
import PlatelyMainPage from './screens/Plately';

function App() {

  const [selectedTab, setSelectedTab] = React.useState<string>('http');
  const [ usr, setUsr ] = React.useState<string>('');

  React.useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    fetch('/api/app/loggedIn')
      .then(response => {
        if (response.status === 200) {
          return response.json() ;
        } else {
          return null;
        }
      })
      .then(data =>setUsr(data))        
      .catch(err => console.log(err));
  };

  const handleNavigate = (option: string) => {
    console.log(`handleNavigate ${option}`);
    setSelectedTab(option.toLowerCase());
  }

  const handleUserLoginSuccess = () => {
    setUsr('test');
  }
  
  return (
    <div className="App">
      <ThemeProvider theme={appTheme} >
        <MenuAppBar onSelectTab={handleNavigate} />

        { !usr && <Login onUserLoginSuccess={handleUserLoginSuccess} />}

        {usr && 
        <div className='content' style={{ marginLeft: 60, marginRight: 60, height: '100%' }}>
          {selectedTab === 'http' && <HttpLogList /> }
          {selectedTab === 'sql' && <SqlLogList /> }        
          {selectedTab === 'plately' && <PlatelyMainPage /> }  
        </div> 
        }

        <div style={{height: 20}} />
        <AppFooter />
      </ThemeProvider>
    </div>
  );
}

export default App;
