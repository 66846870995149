
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import * as React from 'react';

interface AppInfo {
    status: string;
    app: string;
    version?: string;
    buildDate?: string;
}

export function AppFooter() {

  const [appInfo, setAppInfo] = React.useState<AppInfo>(null);

  React.useEffect(() => {
    fetch('/status')
      .then(response => response.json())
      .then(data => setAppInfo(data))
      .catch(err => console.log(err));
  }, []);

  return (
    <footer className="app-footer" >
      <Grid2 padding={2} xs={12} container >
        <Grid2 textAlign={'left'} xs={12} sm={4}>
          Copyright {new Date().getFullYear()} JRB Unlimited, LLC
        </Grid2>
        <Grid2 xs={0} sm={4}></Grid2>
        <Grid2 textAlign={'right'} xs={12} sm={4}>
          {appInfo? <label>version {appInfo.version}  {appInfo.buildDate? '[ ' + appInfo.buildDate + ' ]': ''}</label> : ''}
        </Grid2>
      </Grid2>
      
      
    </footer>
  );
}