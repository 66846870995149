import * as React from 'react';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getNumberComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number },
  b: { [key in Key]: number },
) => number {  
  return order === 'desc'
    ? (a, b) => Number(b[orderBy]) - Number(a[orderBy])
    : (a, b) => Number(a[orderBy]) - Number(b[orderBy]);
    
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export type IdData = { id: number };

export class TableConfig<T extends IdData> {
  public columns: TableColumn[] = [];
  public data: T[] = [];
  public cmdText?: string;
}

export class TableColumn {
  title?: string;
  field = '';
  dataType?: 'number' | 'string' | 'date' | 'boolean' = 'string';
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined = undefined;
  styleFn?: (value: any) => SxProps;
  clickFn?: (value: any) => void;
  textFn?: (value: any) => string;
}

export class FancyTableState {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;  
  order?: Order;
  orderBy?: string;
  rowCount?: number;
  columns: TableColumn[];
}

  
const getAlignValue = (col: TableColumn) => {
  return col.align ? col.align : col.dataType === 'number' ? 'right' : 'left';
}
  
function EnhancedTableHead(props: FancyTableState) {
  const { order, orderBy, onRequestSort } =
    props;

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>

        {props.columns && props.columns.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={getAlignValue(headCell)}
            padding={'normal' }
            sortDirection={orderBy === headCell.field ? order : false}
            style={{fontWeight: 'bold'}}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
              style={{fontWeight: 'bold'}}
            >
              {headCell.title}
              {orderBy === headCell.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export default function FancyTable(props: TableConfig<IdData>) {

  //Bail if there is no data
  if (!props?.columns) {
    return <></>;
  }

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string>(props.columns? props.columns[0].field: '');  
  const [page, setPage] = React.useState(0);  
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const numberFields = props.columns.filter((col) => col.dataType === 'number').map((col) => col.field);
  const useNumberSort = orderBy && numberFields.indexOf(orderBy) >= 0;

  //Reset the page when the data changes
  if (props.data?.length < (page * rowsPerPage)) {
    setPage(0);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';

    console.log(`sorting by ${property}`);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {    
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };


  const getRowColumns = (row: any) : JSX.Element[] =>{ return props.columns.map((colSpec) => {

    return <TableCell align={getAlignValue(colSpec)}
      key={row.id + colSpec.field}
      className={colSpec.clickFn ? 'Table-clickable' : undefined}
      sx={colSpec.styleFn ? colSpec.styleFn(row[colSpec.field]) : undefined}
      onClick={colSpec.clickFn ? () => colSpec.clickFn!(row.id) : undefined}
    >{colSpec.textFn ? colSpec.textFn(row[colSpec.field]) : row[colSpec.field]}</TableCell>;
  
  });};

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>        
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size= 'medium'            
          >
            <EnhancedTableHead              
              order={order}
              orderBy={orderBy}              
              onRequestSort={handleRequestSort}
              rowCount={props.data.length}
              columns={props.columns}                   
            />
            <TableBody>
              {stableSort(props.data, useNumberSort ? getNumberComparator(order, orderBy) : getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {                                    

                  return (
                    <TableRow
                      hover                      
                      tabIndex={-1}
                      key={row.name}                      
                    >
                      { getRowColumns(row) }

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
  );
}
