/* eslint-disable quotes */


/* FORMATTING FUNCTIONS */
export const redStyleIfNonZero = (value: any): any => {
  return value > 0 ? {color: 'red', fontWeight: 'bold'} : {};
}

export const redStyleIfNot200 = (value: any): any => {
  return value != 200 ? {color: 'red', fontWeight: 'bold'} : {};
}

export const redStyleIfNotBlank = (value: any): any => {
  return value ? {color: 'red'} : {};
}

export const addMsLabel = (value: any): any => {
  return value + ' ms';
}

export const showValueIfNonZero = (value: any) => {
  return value > 0 ? value : '';
}

export function timeAgo(date : Date) {

  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  console.log(`timeSince: ${date} ${seconds} ${interval}`);

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}