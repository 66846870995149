import '../style.css';
import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import PlatelyModerationTool from '../components/PlatelyModerationTool';
import PlatelyDeleteUser from '../components/PlatelyDeleteUser';


export default function PlatelyMainPage() {

  const [selTab, setSelTab] = useState<number>(0);


  return (
    <div style={{ textAlign: 'center', maxWidth: 1800, marginLeft: 'auto', marginRight: 'auto' }}>
      <h2>Plately Admin Functions</h2>
      <Grid container >
        <Grid xs={6} md={2} border={'thin'}>
          <Button fullWidth variant={selTab == 0 ? 'contained' : 'outlined'} onClick={() => setSelTab(0)}>Moderation Decisions</Button>
        </Grid>
        <Grid xs={6} md={2} border={'thin'}>
          <Button fullWidth variant={selTab == 1 ? 'contained' : 'outlined'} onClick={() => setSelTab(1)}>Delete User</Button>
        </Grid>
        {/* <Grid xs={6} md={2} border={'thin'}>
          <Button fullWidth variant={selTab == 2 ? 'contained' : 'outlined'} onClick={() => setSelTab(2)}>Do Something Else</Button>
        </Grid> */}
      </Grid>
      <Grid xs={6} md={10} paddingTop={1} minHeight={800} >
        {selTab == 0 && <PlatelyModerationTool />}
        {selTab == 1 && <PlatelyDeleteUser />}
      </Grid>
    </div>
  );
}
