import { HttpRequestLog, HttpRequestStat } from '../types/httpLog';
import { ModerationDecision } from '../../../src/types/platelyMod';
import { SqlLog, SqlStat } from '../types/sqlLog';

export async function getHttpStats(timeFilterHours: number): Promise<HttpRequestStat[]> {
  return fetchSafe<HttpRequestStat>(`/api/app/http/stats?time=${timeFilterHours}`);
}

export async function getHttpLogs(selectedId: number, timeFilterHours: number): Promise<HttpRequestLog[]> {
  return fetchSafe<HttpRequestLog>(`/api/app/http/stats/${selectedId}/details?time=${timeFilterHours}`);
}

export async function getSqlStats(timeFilterHours: number): Promise<SqlStat[]> {
  return fetchSafe<SqlStat>(`/api/app/sql/stats?time=${timeFilterHours}`);
}

export async function getSqlLogs(selectedId: number, timeFilterHours: number): Promise<SqlLog[]> {
  return fetchSafe<SqlLog>(`/api/app/sql/stats/${selectedId}/details?time=${timeFilterHours}`);
}

export async function getModerationDecision(): Promise<ModerationDecision[]> {
  return fetchSafe<ModerationDecision>('/api/app/plately/decisions?pageSize=50');
}

export async function postModerationDecision(url: string, option: any): Promise<void> {
  if (option.params) {

    const params = new URLSearchParams();
    
    option.params.map((e) => (params.set(e.name, e.value)));
    url = url + '?' + params;
  }

  const body = option.body;
  console.log({ url, body });

  const result = await fetch('/api/app/plately/decisions', {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({url, body}),
  })
  if (result.status >= 400) {
    throw new Error((await result.json() as any)?.message ?? 'An error occurred')
  }
}

export async function deletePlatelyUser (email: string): Promise<string> {

  const result = await fetch('/api/app/plately/users', {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({email}),
  })
  if (result.status >= 400) {
    throw new Error((await result.json() as any)?.message ?? 'An error occurred')
  } else {
    return 'User deleted';
  }
}

/* UTILITY FUNCTIONS */
async function fetchSafe<T>(url: string): Promise<T[]> {

  try {
    const retData: T[] = await (await fetch(url, { credentials: 'include' })).json();
    return retData;
  } catch (err) {
    console.error(`Error fetching data from ${url}: ${err}`);
    throw err;
  }

}